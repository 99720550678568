/* src/App.css или src/RulesSlider.css */
.rules-slider-container {
    /* box-sizing: border-box; */
    /* top: 20vh; */
    position: center;
    max-width: 560px; /* Или любой другой подходящий размер */
    max-height: 315px;
    margin: 0 auto;
    padding: 20px;
    
  }
  
  .rule-box {
    font-weight: bold;
    padding: 20px;
    background-color: white; /* Фон белого бокса */
    border-radius: 40px; /* Скругление углов */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень для бокса */
    text-align: center;
  }
  
  .slick-prev, .slick-next {
    background: transparent;
    color: white;
    font-size: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .slick-prev { left: -50px; }
  .slick-next { right: -50px; }
  
  .slick-prev:before, .slick-next:before {
    color: white; /* Или любой цвет, который вам нужен */
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .start-game-button {
    font-weight: 900;
    padding: 10px 20px;
    background-color: yellow; /* или любой другой цвет, который вы предпочитаете */
    color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 20px; /* Для отступа сверху */
    display: block; /* Чтобы сделать кнопку блочным элементом */
    width: auto; /* Чтобы заполнить ширину контейнера */
    box-sizing: border-box; /* Чтобы padding не влиял на общую ширину */
  }

  .gift-image {
    max-width: 100%; /* Убедитесь, что изображение не выходит за пределы своего контейнера */
    height: auto; /* Сохранение пропорций изображения */
    margin-top: 20px; /* Отступ сверху */
  }

  .rules-text{
    background-color: black;
    color: white;
    font-size: 16px; /* Или любой другой размер шрифта */
    font-weight: bold; /* Жирный шрифт */
    padding: 8px; /* Подберите подходящий отступ */
    display: inline-block; /* Чтобы фон шел вплотную к тексту */
    border-radius: 25px; /* Слегка скругленные углы */
  }

  .rules-desc{
    margin: 7vh;
  }

  .container {
    max-width: 100%;
    overflow: hidden;
  }
  
  /* Медиа-запросы для адаптации размера изображения подарка */
  @media (max-width: 1024px) {
    .rules-slider-container{
      padding-top: 15px;
      padding-bottom: 20px;
    }
    .rule-box {
      width: 60%;
      height: auto;
    }
    .slide {
      margin-left: 12vw;
    }
    .gift-image {
      max-width: 75%; /* Меньший размер изображения для планшетов */
    }
    .rule-box h2{
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 860px) {
    .rules-slider-container{
      padding-top: 15px;
      padding-bottom: 20px;
    }
    .rule-box {
      width: 65%;
      height: auto;
    }
    .slide {
      margin-left: 10vw;
    }
    .gift-image {
      max-width: 50%; /* Ещё меньший размер изображения для мобильных устройств */
    }
  }

