.result-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100vh;
    background: url('../img/Form.png') no-repeat center center;
    background-size: contain;
    background-color: black;
  }
  .result-page-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100vh;
    background: url('../img/Form 2.png') no-repeat center center;
    background-size: contain;
    background-color: black;
  }
  .result-page-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100vh;
    background: url('../img/Form 3.png') no-repeat center center;
    background-size: contain;
    background-color: black;
  }
  
  .result-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    max-width: 60%; /* Чтобы содержимое не выходило за пределы экрана на мобильных устройствах */
  }
  .result-container-try {
    
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 15px;
    text-align: left;
    max-width: 60%; /* Чтобы содержимое не выходило за пределы экрана на мобильных устройствах */
    
  }
  
  .result-container h1 {
    font-size: 2em; /* Большой размер для десктопов */
    color: black;
  }
  .result-container-try h1 {
    font-size: 2em; /* Большой размер для десктопов */
    color: black;
  }

  .result-container p {
    margin-top: 10px;
  }
  
  .claim-prize-button {
    background-color: #e63946; /* Цвет вашей кнопки */
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 1em;
  }
  .claim-prize-button:hover {
    background-color: green; /* Устанавливаем цвет фона при наведении (зеленый) */
}
  
.nicetry {
  max-width: 100%;  /* Это свойство делает изображение респонсивным, ограничивая его максимальную ширину до ширины родительского контейнера */
  height: auto;     /* Это свойство сохраняет соотношение сторон изображения */
}

  /* Медиа-запросы для адаптации стилей под мобильные устройства */
  @media (max-width: 960px) {
    .result-container h1 {
      font-size: 1.2em; /* Меньший размер для планшетов и мобильных */
    }
    .result-container-try h1 {
      font-size: 1.2em; /* Меньший размер для планшетов и мобильных */
    }
    .result-container p {
      font-size: 0.7em;
    }
    .result-container-try p {
      font-size: 0.8em;
    }
  
    .claim-prize-button {
      font-size: 0.3em; /* Меньший размер текста кнопки для мобильных устройств */
    }
    .gift-img{
      width: 0.4vw;
    }
  }
  
  @media (max-width: 280px) {
    .result-container {
      padding: 5px;
    }
  
    .result-container h1 {
      font-size: 1.2em; /* Ещё меньший размер для телефонов */
    }
  
    .claim-prize-button {
      padding: 8px 16px; /* Уменьшенные отступы для кнопки */
    }
    .gift-img{
      content: url('../img/120.png');
    }
  }
  
  