/* HomePage */
.home-page {
    background-color: #76b947; /* Цвет фона, как на изображении */
    background-image: url('../img/homepage.png'); /* Путь к вашему фоновому изображению */
    height: 100vh; /* Высота на весь экран */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .content {
    max-width: 600px; /* или нужная ширина */
    padding: 20px;
    border-radius: 15px; /* Скругление углов */
  }
  
  button {
    font-weight: 900;
    padding: 10px 30px;
    background-color: yellow; /* Цвет кнопки */
    color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1.2em; /* Большой размер текста */
    margin-top: 20px; /* Отступ сверху */
  }
  
  button:hover {
    background-color: yellow; /* Цвет при наведении */
  }
  
  h1 {
    color: white;
    font-size: 1.5em; /* Размер шрифта */
  }