body {
  background-color: #000;
  background-attachment: fixed; /* Фиксация фона */
  overflow-x: hidden; /* Запрет масштабирования по горизонтали */
  overflow-y: scroll; /* Разрешение вертикальной прокрутки */
  max-width: 100%; /* Запрет расширения контента за пределы экрана */
}

/* Дополнительные стили, чтобы убрать стандартные отступы и отступы вокруг элементов */
html, body, div, h1, h2, h3, p {
  margin: 0;
  padding: 0;
  border: 0;
}

.bg-img{
  background-image: url('../img/rotate.png');
}


/* src/GameStyles.css */
.game-container {
  position: relative;
  width: 100vw;
  /* Ширина контейнера равна ширине вьюпорта */
  height: 100vh;
  /* Высота контейнера равна высоте вьюпорта */
  background-image: url('../img/game-1/Desktop.png');
  /* Путь к вашему фоновому изображению */
  background-size: 100% auto;
  /* Масштабирование по ширине вьюпорта */
  background-position: center top;
  /* Выравнивание по центру сверху */
  background-repeat: no-repeat;
  /* Без повторения */
}

.game-container-2 {
  position: relative;
  width: 100vw;
  /* Ширина контейнера равна ширине вьюпорта */
  height: 100vh;
  /* Высота контейнера равна высоте вьюпорта */
  background-image: url('../img/image-2.png');
  /* Путь к вашему фоновому изображению */
  background-size: 100% auto;
  /* Масштабирование по ширине вьюпорта */
  background-position: center top;
  /* Выравнивание по центру сверху */
  background-repeat: no-repeat;
  /* Без повторения */
}

.game-container-3 {
  position: relative;
  width: 100vw;
  /* Ширина контейнера равна ширине вьюпорта */
  height: 100vh;
  /* Высота контейнера равна высоте вьюпорта */
  background-image: url('../img/image-3.png');
  /* Путь к вашему фоновому изображению */
  background-size: 100% auto;
  /* Масштабирование по ширине вьюпорта */
  background-position: center top;
  /* Выравнивание по центру сверху */
  background-repeat: no-repeat;
  /* Без повторения */
}

.rules-container {
  position: relative;
  width: 100vw;
  /* Ширина контейнера равна ширине вьюпорта */
  height: 100vh;
  /* Высота контейнера равна высоте вьюпорта */
  background-size: 100% auto;
  /* Масштабирование по ширине вьюпорта */
  background-position: center top;
  /* Выравнивание по центру сверху */
  background-repeat: no-repeat;
  /* Без повторения */
}

.santa-img {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hidden {
  visibility: hidden;
}

.temp-santa-img {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Ваш CSS файл */
.center-div {
  display: grid;
  place-items: center;
  height: 80vh;
  /* Высота вьюпорта */
}

.center-div-2 {
  display: grid;
  place-items: center;
  height: 80vh;
  /* Высота вьюпорта */
}

.form-box {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-box {
  width: 400px;
  /* Ширина квадрата */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Центрирование текста внутри квадрата */

}

.play-button {
  background-color: yellow;
  /* Жёлтый фон */
  border: none;
  /* Убрать стандартную рамку */
  color: black;
  /* Цвет текста */
  padding: 10px 20px;
  /* Внутренние отступы */
  border-radius: 25px;
  /* Полукруглые края */
  font-size: 16px;
  /* Размер шрифта */
  cursor: pointer;
  /* Курсор в виде указателя */
  transition: 0.3s;
  /* Плавный эффект для наведения */
}

.play-button:hover {
  background-color: #ffd700;
  /* Смена цвета при наведении */
}


/* Стиль для мобильных устройств */
@media screen and (max-width: 768px) {
  h1 {
    display: inline-block;
  }

  h2 {
    display: block;
  }
}



.image-santa {
  z-index: 1 !important;
  position: absolute;
}




.image-santa-1 {
  left: calc(54vw - 5px);
  bottom: calc(4vw - -3px);
  width: 10vw;
}

.image-santa-2 {
  left: calc(36vw - 1px);
  bottom: calc(6vw - 5px);
  width: 2.5vw;

}

.image-santa-3 {
  left: calc(5vw - 16px);
  bottom: calc(6vw - 1px);
  width: 3vw;

}
.image-santa-4 {
  right: calc(15vw - 7px);
  bottom: calc(9vw - 7px);
  width: 2.5vw;

}
.image-santa-5 {
  left: calc(36vw - 7px);
  bottom: calc(27vw - 7px);
  width: 2.5vw;

}

.image-santa-visible {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20vw;
}

.background-wrapper {
  position:
    absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  inset: 0;
  background-color: #000;

}

.background-block {
  position: relative;
}


.background-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}